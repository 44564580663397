import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styles from "./Arrow.module.css"

const Arrow = ({ direction, color }) => {
  return (
    <div className={`${styles.arrowWrapper} ${styles[`GameArrow${direction}`]}`}>
      <div className={`${styles.arrowTop} ${styles.arrowLayer}`} style={{ backgroundColor: color }}></div>
      <div className={`${styles.arrowMiddle} ${styles.arrowLayer}`} style={{ backgroundColor: color }}></div>
      <div className={`${styles.arrowBottom} ${styles.arrowLayer}`} style={{ backgroundColor: color }}></div>
      <div className={`${styles.arrowTail} ${styles.arrowLayer}`} style={{ backgroundColor: color }}></div>

    </div>
  );
}

export default Arrow;