import React from "react"
import { Link } from "gatsby"
import globalStyles from "./global.module.css"
import styles from "./collaborate.module.css"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Arrow from "../components/base/Arrow"
import SEO from "../components/seo"

const Collaborate = ({ data }) => (
  <Layout headerBackground={"#fff"} navColor={"#222"}>
    <SEO
      title={`Work Together ~ Long Weekend`}
      description={
        "Whether you’ve got something fully scoped and designed, or you’re starting with a rough concept, we can help you build something great."
      }
    />
    <div className={styles.wrapper}>
      <div className={globalStyles.marginBottomNormal}>
        <h1>Work Together</h1>
        <p>
          We build digital products for internet connected devices, native or on
          the web. We also design, brand, and take products to market.
        </p>

        <p>
          We place a strong emphasis on concept validation and securing
          meaningful early-stage traction.
        </p>

        <p>
          We’re small and take on a handful of external projects a year. The
          projects we do work on get the best of us.
        </p>

        <p>
          Whether you’ve got something fully scoped and designed, or you’re
          starting with a rough concept, we can help you build something great.
        </p>
      </div>
      <div className={styles.contactBox}>
        <div className={styles.tree}>
          <Img fluid={data.tree.childImageSharp.fluid} />
        </div>
        <div>
          <p style={{ marginBottom: 24 }}>
            Tell us about your project, and we'll follow up to schedule a call.
          </p>
          <a href="mailto:hello@longweekend.io" className={styles.email}>
            hello@longweekend.io
          </a>
        </div>
      </div>
      <div className={globalStyles.marginBottomNormal}>
        <h2>Capabilities</h2>
        <div className={styles.personWrap}>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <Img fluid={data.lighthouse.childImageSharp.fluid} />
            </div>
            <div>
              <h3>Concept Validation</h3>

              <p style={{ marginBottom: 0 }}>
                We help founders get tangible traction before writing a single
                line of code. Through rapid experimentation, we identify target
                markets, validate acquisition channels, build business models,
                and define product-vision.
              </p>
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <Img fluid={data.palette.childImageSharp.fluid} />
            </div>
            <div>
              <h3>Design</h3>

              <p style={{ marginBottom: 0 }}>
                We offer design services from conceptualizing initial digital
                brand guides and design systems, to UX development and user
                interviews, to high-fidelity user interface design and
                prototyping. Through our process we collaborate closely and
                iterate to deliver a bespoke design that is intuitive and
                extensible.
              </p>
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <Img fluid={data.computer.childImageSharp.fluid} />
            </div>
            <div>
              <h3>Engineering</h3>

              <p style={{ marginBottom: 0 }}>
                We offer a full stack development service with an emphasis on
                right sized engineering. We build on web, mobile, web3 (eth and
                polygon), and GPT, but can pick up any tech to fit the solution.
                Frameworks and libs we like: Next, tRPC, Nest, Tailwind, React
                Native.
              </p>
            </div>
          </div>
          <div className={styles.person}>
            <div className={styles.personImg}>
              <Img fluid={data.rocket.childImageSharp.fluid} />
            </div>
            <div>
              <h3>Go-To-Market / Growth</h3>

              <p style={{ marginBottom: 0 }}>
                We design and validate go-to-market strategies for successful
                product launches and leverage continuous experimentation to fuel
                rapid growth. We do things that don’t scale, so we can identify
                the things that do.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2>Our Team</h2>
      <div className={styles.personWrap}>
        <div className={styles.person}>
          <div className={styles.personImg}>
            <Img fluid={data.adrian.childImageSharp.fluid} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/adrian-diaz-18721431/",
                  "_blank"
                )
              }}
            >
              <h3>Adrian</h3>
              <div style={{ marginLeft: "12px", marginBottom: "10px" }}>
                <Arrow color={"#000"} direction="RIGHT" />
              </div>
            </div>

            <p style={{ marginBottom: 0 }}>
              Tackles Long Weekend's toughest engineering challenges and keeps
              internal and client projects running smoothly. MBA from Wharton
              and MSc Computer Science from Penn. Enjoys meditation, minimalism
              and witbier.
            </p>
          </div>
        </div>
        <div className={styles.person}>
          <div className={styles.personImg}>
            <Img fluid={data.dylan.childImageSharp.fluid} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/dylan-petro-079aa637/",
                  "_blank"
                )
              }}
            >
              <h3>Dylan</h3>
              <div style={{ marginLeft: "12px", marginBottom: "10px" }}>
                <Arrow color={"#000"} direction="RIGHT" />
              </div>
            </div>
            <p style={{ marginBottom: 0 }}>
              Product strategy. Always looking to make things easy and fun to
              use. BA and MSc Computer Science from Penn. Enjoys learning about
              nutriton, watching the (W)NBA and doodling.
            </p>
          </div>
        </div>
        <div className={styles.person}>
          <div className={styles.personImg}>
            <Img fluid={data.forrest.childImageSharp.fluid} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/johnforrestmilburn/",
                  "_blank"
                )
              }}
            >
              <h3>Forrest</h3>
              <div style={{ marginLeft: "12px", marginBottom: "10px" }}>
                <Arrow color={"#000"} direction="RIGHT" />
              </div>
            </div>
            <p style={{ marginBottom: 0 }}>
              Product design and front-end development. Lives to distill
              problems down to their essence and turn loose, abstract ideas into
              tangible, cogent product. MSE in Integrated Product Design from
              Penn. Enjoys creativity in all forms and things that go fast.
            </p>
          </div>
        </div>
        <div className={styles.person}>
          <div className={styles.personImg}>
            <Img fluid={data.mike.childImageSharp.fluid} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/chambers-mike/",
                  "_blank"
                )
              }}
            >
              <h3>Mike</h3>
              <div style={{ marginLeft: "12px", marginBottom: "10px" }}>
                <Arrow color={"#000"} direction="RIGHT" />
              </div>
            </div>
            <p style={{ marginBottom: 0 }}>
              Go-to-market, product strategy, and growth. Mike is a 3x founder
              and growth hacker who thrives in the gritty, 0-1 phase of
              startups. Enjoys climbing mountains, running, and spending time
              with his three kids.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Collaborate

export const pageQuery = graphql`
  query {
    tree: file(relativePath: { eq: "tree.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adrian: file(relativePath: { eq: "mariopeeps-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dylan: file(relativePath: { eq: "mariopeeps-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    forrest: file(relativePath: { eq: "mariopeeps-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mike: file(relativePath: { eq: "mariopeeps-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    lighthouse: file(relativePath: { eq: "lighthouse_sat.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    palette: file(relativePath: { eq: "palette_sat.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    computer: file(relativePath: { eq: "computer_sat.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rocket: file(relativePath: { eq: "rocket_sat.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
